//==============================================================================
// Global Constants
//
// Consolidated list of constants reused across different modules and extensions
//==============================================================================

//----------------------------------------------------------
// Attribute names list
//----------------------------------------------------------
export const attrNames = {
    attributeValueItemOverweight: "Item Overweight",
    attributeValueItemAvailable: "Item Available ",
    attributeValueWidth: "Width (inch)",
    attributeValueWeight: "Weight (lbs)",
    attributeValueLength: "Length (inch)",
    attributeValueKit:"Kit",
    attributeValueModel:"Model",
    attributeValuePart:"Part",
    attributeValueProductType: "ProductType",
    attributeValueProductLeadTime: "ProductLeadTime",
    poBoxVariations: ["P.O. Box", "PO Box", "POBOX", "P.o Box"],
    attributeValuePreviousPartNumbers:"PreviousPartNumbers",
    urlRefinerRefiners: 'refiners',
    itemNumber: 'Item No.',
    itemName: 'Item Name',
    quantity: 'Qty',
    unitOfMeasure: 'UOM'
};

//----------------------------------------------------------
// Global label resources
//----------------------------------------------------------
export const labelResources = {};
